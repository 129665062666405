import React from 'react'
import {Link} from 'exo';
const Footer = () => {
    return (
        <div className='w-full bg-white'>
        <div className='max-w-full mx-auto'>
            <div className='footer flex md:text-sm flex-col sm:flex-row justify-between py-4 px-10 pb-3 md:pb-0 md:pt-2'>
               
               <p>
                © 2021 Auto Genius Ventures LLC
               </p>
               
               <a target="new" href="https://googlechrome.github.io/lighthouse/viewer/?psiurl=https%3A%2F%2Fwww.autogenius.io%2F&strategy=desktop">
               <strong>Lighthouse Score</strong>
               </a>
               <a target="new" href="https://www.linkedin.com/company/autogeniusio/" >
               <strong>Connect on LinkedIn</strong>
               </a>
               <Link className="md:text-xs" aria-label='Terms of Service' to='/terms'>
               Terms of Service
               </Link>
               <Link aria-label='Privacy Policy' to='/privacy-policy'>
               Privacy Policy
               </Link>
               <Link aria-label='Code of Conduct' to='/code-of-conduct'>
               Code of Conduct
               </Link>
               
              
            </div>
        </div>
        </div>
    )
}

export default Footer
